import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

const gpts = [
  {
    name: '图像异常检测助手',
    role: '知识库含最新顶会SOTA论文，如SimpleNet，WinCLIP，April-Gan等',
    imageUrl:
        'https://img.ziuch.top/i/2024/05/07/r5oubs-1709194236434.webp',
    xUrl: '#',
    linkedinUrl: 'https://fastgpt.ziuch.com/chat/share?shareId=p79afdtxdj3igtpwkmykk7vr',
  },
  {
    name: '3D异常检测助手',
    role: '知识库含最新顶会SOTA论文，EasyNet，M3DM，CPMF，AST等',
    imageUrl:
        'https://img.ziuch.top/i/2024/05/07/r5tm7m-1709194236434.webp',
    xUrl: '#',
    linkedinUrl: 'https://fastgpt.ziuch.com/chat/share?shareId=te3ie76oosubzmz6bnltpo6s',
  },
  {
    name: 'ziuch的博客助手',
    role: '知识库含ziuch所有公开的博客内容，如技术分享和知行合一等',
    imageUrl:
        'https://img.ziuch.top/i/2024/02/04/nc670-2.webp',
    xUrl: '#',
    linkedinUrl: 'https://fastgpt.ziuch.com/chat/share?shareId=85x3m2g9zgdpkhqr8j1380cd',
  },
]

root.render(
    <React.StrictMode>
      <div className="relative isolate overflow-hidden bg-gray-900">
        <svg
            className="absolute inset-0 -z-10 h-full w-full stroke-white/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
            aria-hidden="true"
        >
          <defs>
            <pattern
                id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
            >
              <path d="M.5 200V.5H200" fill="none"/>
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-800/20">
            <path
                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"/>
        </svg>
        <div
            className="absolute left-[calc(50%-4rem)] top-10 -z-10 transform-gpu blur-3xl sm:left-[calc(50%-18rem)] lg:left-48 lg:top-[calc(50%-30rem)] xl:left-[calc(50%-24rem)]"
            aria-hidden="true"
        >
          <div
              className="aspect-[1108/632] w-[69.25rem] bg-gradient-to-r from-[#80caff] to-[#4f46e5] opacity-20"
              style={{
                clipPath:
                    'polygon(73.6% 51.7%, 91.7% 11.8%, 100% 46.4%, 97.4% 82.2%, 92.5% 84.9%, 75.7% 64%, 55.3% 47.5%, 46.5% 49.4%, 45% 62.9%, 50.3% 87.2%, 21.3% 64.1%, 0.1% 100%, 5.4% 51.1%, 21.4% 63.9%, 58.9% 0.2%, 73.6% 51.7%)',
              }}
          />
        </div>
        <div className="mx-auto max-w-7xl px-6 pb-24 pt-10 lg:flex lg:px-8 lg:py-40 lg:pb-0">
          <div className="mx-auto max-w-2xl flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8">
            <img
                className="h-11"
                src="https://tailwindui.starxg.com/img/logos/mark.svg?color=indigo&shade=500"
                alt="Your Company"
            />
            <div className="mt-24 sm:mt-32 lg:mt-16">
              <a href="https://github.com/labring/FastGPT/releases/" target="_blank" className="inline-flex space-x-6">
              <span
                  className="rounded-full bg-indigo-500/10 px-3 py-1 text-sm font-semibold leading-6 text-indigo-400 ring-1 ring-inset ring-indigo-500/20">
                What's new
              </span>
                <span className="inline-flex items-center space-x-2 text-sm font-medium leading-6 text-gray-300">
                  <span>Latest V4.7.1</span>
                <ChevronRightIcon className="h-5 w-5 text-gray-500" aria-hidden="true"/>
              </span>
              </a>
            </div>
            <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-6xl">
              This is your own GPT
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              FastGPT is a knowledge-based platform built on the LLMs, offers a comprehensive suite of out-of-the-box
              capabilities such as data processing, RAG retrieval, and visual AI workflow orchestration, letting you
              easily develop and deploy complex question-answering systems without the need for extensive setup
              or configuration.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <a
                  href="https://github.com/labring/FastGPT"
                  target="_blank"
                  className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
              >
                Get started
              </a>
              <a href="https://doc.fastai.site/docs/intro/" target="_blank" className="text-sm font-semibold leading-6 text-white">
                Learn more <span aria-hidden="true">→</span>
              </a>
            </div>
          </div>
          <div
              className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
              <img
                  src="https://img.ziuch.top/i/2024/05/07/pjwga6-1709194236434.webp"
                  alt="App screenshot"
                  width={2432}
                  height={1442}
                  className="w-[76rem] rounded-md bg-white/5 shadow-2xl ring-1 ring-white/10"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900 py-24 sm:py-12">
        <div className="mx-auto max-w-7xl px-6 text-center lg:px-8">
          <div className="mx-auto max-w-2xl">
            <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">Chat with my GPTs</h2>
            <p className="mt-4 text-lg leading-8 text-gray-400">
              These are image anomaly detection, 3D anomaly detection, and GPTs of ziuch’s blog
            </p>
          </div>
          <ul
              role="list"
              className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-6 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3 lg:gap-8"
          >
            {gpts.map((gpt) => (
                <li key={gpt.name} className="rounded-2xl bg-gray-800 px-8 py-10">
                  <img className="mx-auto h-48 w-48 rounded-full md:h-56 md:w-56" src={gpt.imageUrl} alt=""/>
                  <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-white">{gpt.name}</h3>
                  <p className="text-sm leading-6 text-gray-400">{gpt.role}</p>
                  <ul role="list" className="mt-6 flex justify-center gap-x-6">
                    {/*<li>*/}
                    {/*  <a href={person.xUrl} className="text-gray-400 hover:text-gray-300">*/}
                    {/*    <span className="sr-only">X</span>*/}
                    {/*    <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">*/}
                    {/*      <path*/}
                    {/*          d="M11.4678 8.77491L17.2961 2H15.915L10.8543 7.88256L6.81232 2H2.15039L8.26263 10.8955L2.15039 18H3.53159L8.87581 11.7878L13.1444 18H17.8063L11.4675 8.77491H11.4678ZM9.57608 10.9738L8.95678 10.0881L4.02925 3.03974H6.15068L10.1273 8.72795L10.7466 9.61374L15.9156 17.0075H13.7942L9.57608 10.9742V10.9738Z"/>*/}
                    {/*    </svg>*/}
                    {/*  </a>*/}
                    {/*</li>*/}
                    <li>
                      {/*<a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-300">*/}
                      {/*  <span className="sr-only">LinkedIn</span>*/}
                      {/*  <svg className="h-5 w-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">*/}
                      {/*    <path*/}
                      {/*        fillRule="evenodd"*/}
                      {/*        d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"*/}
                      {/*        clipRule="evenodd"*/}
                      {/*    />*/}
                      {/*  </svg>*/}
                      {/*</a>*/}
                      <a
                          href={gpt.linkedinUrl}
                          target="_blank"
                          className="rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-400"
                      >
                        Let's Chat
                      </a>
                    </li>
                  </ul>
                </li>
            ))}
          </ul>
        </div>
      </div>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
